@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  height: 100%;
  width: 100%;
  position: relative;
  .logo {
    position: absolute;
    margin-top: 20px;
    margin-left: 30px;
    z-index: 10;
    display: none;
    @media only screen and (min-width: $xl-screen) {
      z-index: 11;
      display: block;
      margin-top: 0px;
      margin-left: 0px;
      img { 
        width: 300px;
      }
    }
    transform: scale(1.1);
  }
  header {
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 10;
    width: 100vw;
    h1 {
      color: $dark;
      font-size: 16px;
      line-height: 16px;
      background-color: $white;
      text-align: center;
      border-bottom-right-radius: 25px;
      position: absolute;
      padding: 20px 30px 20px 30px;
      min-width: 238px;
      @media only screen and (min-width: $xl-screen) {
        position: relative;
        font-size: 35px;
        line-height: 35px;
        font-size: 35px;
        line-height: 35px;
        min-width: 400px;
        padding: 30px 30px 20px 30px;
        border-bottom-left-radius: 25px;
      }
    }
    .toggle {
      width: 148px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba($dark, 1);
  }
  .loader {
    background-color: rgba($dark, 1);
    width: 100%;
    height: 100%;
    @include flex-center;
  }
}
@import '../../styles/variables';
@import '../../styles/mixins';

.marker {
  pointer-events: none;
  display: flex;
  .content {
    @include flex-center;
    flex-direction: column;
    img {
      position: relative;
      z-index: 10;
    }
    h2 {
      position: relative;
      background-color: $white;
      text-align: center;
      max-width: 400px;
      z-index: 1;
      border-radius: 30px;
      @include semiBold;
      color: $dark;
      padding: 12px 20px;
      margin: 0px;
      font-size: 16px;
      transform: translateY(-26px);
      svg {
        color: $warn;
        transform: translate(-1px, 1px);
        font-size: 18px;
      }
      // box-shadow: 0px 3.335416555404663px 11.673957824707031px rgba(0, 0, 0, 0.25);
    }
  }
}

.camera {
  position: absolute;
  right: 20px;
  top: 20px;
  @include btn;
}
.scene {
  pointer-events: none;
  display: block;
  .content {
    @include flex-center;
    position: relative;
    flex-direction: column;
    padding: 10px;
    background-color: red;
    .circle {
      @include flex-center;
      position: relative;
      flex-direction: column;
      background-color: $white;
      z-index: 10;
      width: 400px;
      height: 400px;
      max-width: 400px;
      max-height: 400px;
      border-radius: 400px;
      box-shadow: 0px 3.335416555404663px 11.673957824707031px rgba(0, 0, 0, 0.25);
      border: 16px solid $primary-color;
      h2 {
        position: relative;
        @include semiBold;
        color: $dark;
        padding: 12px 20px;
        margin: 0px;
        font-size: 62px;
        line-height: 62px;
        z-index: 10;
        text-align: center;
        svg {
          color: $warn;
          transform: translate(-2px, 3px);
          font-size: 20px;
        }
      }
    }
    .arrow {
      position: relative;
      z-index: 1;
      transform: translateY(-4px) scale(1.5);
    }
  }
}

@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  height: 100%;
  width: 100%;
  padding: 45px;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 15px;
    h1 {
      margin: 0px;
      color: $dark;
      font-size: 28px;
      line-height: 28px;
    }
    .actions {
      display: flex;
      align-items: center;
      .new {
        display: flex;
        align-items: center;
        margin-right: 10px;
        h4 {
          margin: 0px;
          margin-right: 10px;
          color: $dark;
        }
        .switch {
          border-radius: 20px;
          margin: 0px 5px;
          height: 34px;
          width: 70px;
          display: flex;
          border: 2px solid $white;
          align-items: center;
          cursor: pointer;
          position: relative;
          box-shadow: 0px 0px 26.438px rgba(2, 53, 67, 0.15);
          p {
            width: 50%;
            margin: 0px;
            @include semiBold;
            color: $dark;
            opacity: 1;
            transition: 0.4s;
            &:first-child {
              opacity: 0;
            }
          }
          .round {
            width: 23px;
            height: 23px;
            border-radius: 30px;
            position: absolute;
            background-color: #9CA5A7;
            left: 4px;
            transition: 0.4s;
          }
          &.active {
            p {
              opacity: 0;
              &:first-child {
                opacity: 1;
              }
            }
            .round {
              background-color: #E42313;
              left: 39px;
            }
          }
        }
      }
      .btn {
        @include btn;
        &.export {
          padding: 0px;
          width: 45px;
          height: 45px;
          margin-right: 10px;
          svg {
            font-size: 20px;
          }
        }
      }
    }
  }
  .img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc(100% - 50px);
    border-radius: 10px;
    background-color: rgba($primary-color, 0.1);
    &.add {
      cursor: url('../../../src/assets/images/cursor.svg'), auto !important;
    }
    .file {
      position: absolute;
      z-index: 200;
      top: 20px;
      left: 20px;
    }
  }
  .loader {
    width: 100%;
    height: 100%;
    @include flex-center;
  }
}
@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  top: 0px;
  z-index: 200;
  height: 100vh;
  width: 100%;
  background-color: rgba($primary-color, 0.6);
  @include transition;
  overflow: hidden;
  @include flex-center;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  .media {
    position: relative;
    margin-top: 5%;
    height: 90%;
    z-index: 102;
    max-width: 90%;

    &.video {
      width: 90%;
      height: 100%;

      video {
        background-color: $dark;
        width: 100%;
        object-fit: contain;
      }
    }

    @media only screen and (min-width: $md-screen) {
      margin-top: 10%;
    }

    .close {
      position: absolute;
      background-color: $primary-color;
      z-index: 220;
      width: 30px;
      height: 30px;
      right: -10px;
      top: -10px;
      border: none;
      border-radius: 50px;
      cursor: pointer;
      @include transition;
      pointer-events: all;
      @include flex-center;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 14px;
        pointer-events: all;
        object-fit: contain;
      }

      @media only screen and (min-width: $md-screen) {
        width: 50px;
        height: 50px;
        right: -15px;
        top: -15px;

        img {
          width: 22px;
        }
      }
    }

    img {
      object-fit: contain;
      max-width: 100%;
      height: auto;
      border-radius: 10px;

      @media only screen and (min-width: $md-screen) {
        height: 100%;
        max-height: 70vh;
      }
    }

    video {
      border-radius: 10px;
      object-fit: contain;
      overflow: hidden;
      width: 100%;
      max-height: 90vh;

      @media only screen and (min-width: $md-screen) {
        height: auto;
        max-height: 80vh;
      }
    }
  }
}
@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  height: 100%;
  width: 100%;
  padding: 30px 45px;
  a {
    display: flex;
    @include semiBold;
    text-decoration: none;
    align-items: center;
    color: $dark;
    font-size: 14px;
    img {
      margin-right: 8px;
    }
  }
  form {
    header {
      margin: 20px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .name {
        width: 70%;
        max-width: 690px;
        input {
          width: 100%;
          margin: 0px;
          color: $dark;
          font-size: 28px;
          line-height: 28px;
          background-color: transparent;
          border: none;
          @include semiBold;
          border-bottom: $border;
        }
      }
      .actions {
        display: flex;
        button {
          margin-left: 10px;
          @include btn;
          &.saved {
            color: rgba($black, 0.5);
          }
          &:nth-child(2) {
            color: $black;
          }
        }
      }
    }
    .new {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 20px;
      h4 {
        margin: 0px;
        margin-right: 10px;
        color: $dark;
      }
      .switch {
        border-radius: 20px;
        margin: 0px 5px;
        height: 34px;
        width: 70px;
        display: flex;
        border: 2px solid $white;
        padding: 0px 6px;
        align-items: center;
        cursor: pointer;
        position: relative;
        box-shadow: 0px 0px 26.438px rgba(2, 53, 67, 0.15);
        p {
          width: 50%;
          margin: 0px;
          @include semiBold;
          color: $dark;
          opacity: 1;
          transition: 0.4s;
          &:first-child {
            opacity: 0;
          }
        }
        .round {
          width: 23px;
          height: 23px;
          border-radius: 30px;
          position: absolute;
          background-color: #9CA5A7;
          left: 4px;
          transition: 0.4s;
        }
        &.active {
          p {
            opacity: 0;
            &:first-child {
              opacity: 1;
            }
          }
          .round {
            background-color: #E42313;
            left: 39px;
          }
        }
      }
    }
    .select-tabs {
      display: flex;
      button {
        margin-right: 10px;
        @include btn;
      }
    }
    .cover {
      max-width: 700px;
      width: 100%;
      margin: 20px 0px;
      .media {
        width: 100%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        img, video {
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
        }
        button {
          @include btn;
          position: absolute;
          bottom: 20px;
          right: 20px;
          color: $warn;
        }
      }
      .file {
        width: 99%;
        height: 200px;
        @include flex-center;
        flex-direction: column;
        border-radius: 10px;
        @include customBorder;
      }
    }
    .content {
      width: 100%;
      border-top: $border;
      margin-bottom: 20px;
      .tab {
        width: 100%;
        padding-bottom: 20px;
        margin: 20px 0px;
      }
    }
  }
  .loader {
    width: 100%;
    height: 100%;
    @include flex-center;
  }
}
@import '../../styles/variables';
@import '../../styles/mixins';

.marker {
  position: absolute;
  display: flex;
  touch-action: none;
  .content {
    @include flex-center;
    flex-direction: column;
    cursor: pointer;
    @include transition;
    &:hover {
      transform: scale(1.07);
    }
    img {
      position: relative;
      z-index: 2;
      width: 70px;
    }
    h2 {
      position: relative;
      background-color: $white;
      text-align: center;
      max-width: 400px;
      min-width: 200px;
      z-index: 1;
      border-radius: 30px;
      @include semiBold;
      color: $dark;
      padding: 12px 20px;
      margin: 0px;
      font-size: 16px;
      transform: translateY(-26px);
      svg {
        color: $warn;
        transform: translate(-2px, 3px);
        font-size: 20px;
      }
    }
  }
}
.dragging {
  position: absolute;
}
.content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }
}
